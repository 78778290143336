.contacts-container {
    width: 90%;
    margin: auto;
}

.search-bar {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

table,
th,
td {
    border: 1px solid #ddd;
}

th,
td {
    padding: 12px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.edit-contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.edit-contact-form label {
    font-weight: bold;
}

.edit-contact-form input,
.edit-contact-form textarea {
    font-family: Arial, sans-serif;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.hidden {
    display: none;
}
.edit-contact-form button:first-child {
    /* Cancel button */
    background-color: white;
    border: 1px solid #ddd;
    color: #333;
    cursor: pointer;
}

.edit-contact-form button:last-child {
    /* Save button */
    background-color: #4CAF50;
    border: none;
    color: white;
    cursor: pointer;
}

.edit-contact-form {
    text-align: left;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}